@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
::-webkit-scrollbar {
  display: none;
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}